import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export const DropdownSelectCustom = (props) => {
    const { value, options, returnCallback } = props;

    const handleChange = (selection) => {
        returnCallback(selection);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        ref={ref}
        onClick={(e) => {
        e.preventDefault();
        onClick(e);
        }}
    >
        {children}
    </a>
    ));

    const menuItems = options.map((option) => (
        <Dropdown.Item hidden={option.hidden} disabled={option.disabled} eventKey={option.id} key={option.id}>
            <span className="dropdown-select-custom-span">{option.name.toUpperCase()}</span>
        </Dropdown.Item>
    ));

    return (
        <div className='position-absolute z-1'>
        <Dropdown
            drop="start"
            show={props.show}
            onToggle={props.onToggle}
            key={"dropdown-select"}
            id={"dropdown-select-custom"}
            disabled={props.disabled}
            onSelect={(event) => handleChange(event)}>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                ...
            </Dropdown.Toggle>
            <Dropdown.Menu flip={true} align={"start"}>
                {menuItems}
            </Dropdown.Menu>
        </Dropdown>
        </div>
    );
};
