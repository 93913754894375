import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

export const DropdownSelect = (props) => {
    const { value, options, returnCallback } = props;

    const handleChange = (selection) => {
        returnCallback(selection);
    };

    const menuItems = options.map((option) => (
        <Dropdown.Item hidden={option.hidden} disabled={option.disabled} className="dropdown-select-mi" eventKey={option.id} key={option.id}>
            <span id="dropdown-select-span">{option.name.toUpperCase()}</span>
        </Dropdown.Item>
    ));

    let title = "";
    let variant = "success";
    options.forEach(option => {
        if (option.id === value) {
            title = option.name;
            variant = option.variant ?? "success";
        }
    });

    return (
        <DropdownButton
            size="lg"
            variant={variant}
            title={title.toUpperCase()}
            key={"dropdown-select"}
            id={"dropdown-select"}
            disabled={props.disabled}
            onSelect={(event) => handleChange(event)}>
            {menuItems}
        </DropdownButton>
    );
};
