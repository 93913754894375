import React from 'react';
import { WORK_FLOW_TYPES, VSO_STATES, VSO_STATE_INFO, VSO_PARTNER_TESTING_STATES, VSO_PARTNER_TESTING_STATE_INFO } from '../../Constants/enums';
import Auth from '../../Auth/Auth';
import { FormFieldValidationResult } from '../../Components/FormFields/FormField';
import { MisSignOffState } from './MarketplaceDetails';

export type AdoIdentity = {
    displayName: string
}

export type DisplayField<V> = {
    name: string;
    value?: V;
    id: string;
    url?: string;
}

export class OfferField<V> {
    value?: V;
    originalValue?: V;
    displayName: string;
    validate?: ((offer?: any, value?: V, originalValue?: V, enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) => FormFieldValidationResult);
    serverExport: boolean;

    constructor(displayName: string, serverExport: boolean, value?: V) {
        this.value = value;
        this.originalValue = value;
        this.displayName = displayName;
        this.serverExport = serverExport;
    }

    convertToDisplayField(productDetails: DisplayField<V>[], url?: string) {

        if (this.value !== null) {
            let displayField: DisplayField<V> = {
                name: this.displayName,
                value: this.value,
                id: this.displayName.replace(/\s+/g, ''),
                url: url
            }

            productDetails.push(displayField);
        }
    }

    copyValue(offerField: OfferField<V>) {
        this.value = offerField.value;
    }
}

export const visibleToOfferAndMetaWorkFlow = [ WORK_FLOW_TYPES.METADATA_ONLY, WORK_FLOW_TYPES.OFFER];
export const visibleToMetaWorkFlow = [WORK_FLOW_TYPES.METADATA_ONLY];

interface Props {
    offerId: string
    cardId: string
    auth: Auth
    workflowType: string
}

export class MarketplaceItem {
    auth: Auth;
    workflowType = new OfferField<string>("Workflow Type - not for display", false);

    constructor(data: Props) {
        this.auth = data.auth;
        this.workflowType.value = data.workflowType;
    }

    export() {
        var exportObject: any = {};
        for (var prop in this) {
            if (this.hasOwnProperty(prop)) {
                if (this[prop] && this[prop] instanceof OfferField) {
                    const offerField = this[prop] as OfferField<any>;

                    if (offerField.value !== null && offerField.serverExport) {
                        exportObject[prop] = offerField.value;
                    }
                }
            }
        }

        if (exportObject.auth) {
            delete exportObject.auth;
        }

        return exportObject;
    }

    validateOffer(enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, originalValues: any = null) : { passed: boolean, results: FormFieldValidationResult[] } {
        let results: FormFieldValidationResult[] = [];
        let passed = true;
        console.log("--------BEGINNING VALIDATION--------");
        for (var prop in this) {
            if (this.hasOwnProperty(prop) && this[prop] instanceof OfferField) {
                const offerField = this[prop] as OfferField<any>;

                if (offerField.validate) {
                    var originalValue = offerField.originalValue;

                    if (originalValue === undefined && originalValues !== null && originalValues.hasOwnProperty(prop)) {
                        originalValue = originalValues[prop].value;
                    }

                    let result = offerField.validate(this, offerField.value, originalValue, enablePCAndXbox, enablePCOnly, enableXboxOnly);

                    result.propertyName = prop;
                    result.hasChanged = (
                        offerField.value === originalValue ||
                        Array.isArray(offerField.value) && Array.isArray(originalValue) && offerField.value.length === (originalValue as [])?.length && offerField.value.every((val, index) => val === (originalValue as [])[index])) ?
                            false :
                            true;

                    results.push(result);

                    if (result.blockSubmission || (result.result !== 'success' && result.result !== 'warning') ) {
                        console.log(`${prop} failed validation with error: ${result.message}`)
                        passed = false;
                    } else if (result.result === 'warning' && result.requestConfirmation) {
                        console.log(`${prop} passed validation with warning: ${result.message}`)
                    }
                }
            }
        }

        if (passed) {
            console.log("------PASSED VALIDATION--------");
        } else{
            console.log("--------FAILED VALIDATION--------");
        }

        return {
            passed: passed,
            results: results
        }
    }

    getTitle() {
        throw new Error(`getTitle not defined in ${typeof this}`);
    }

    
    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        if (this.hasOwnProperty(propertyName)) {
            if (propertyValue !== undefined) {
                (this[propertyName] as OfferField<any>).value = propertyValue;
            }

            return this;
        } else {
            console.log(`Can't find prop "${propertyName}" in ${typeof this}!`, this);
            throw new Error(`Can't find prop "${propertyName}" in ${typeof this}!`);
        }
    }

    getSubmissionFormFields(formInfoCallback: <T extends MarketplaceItem, V>(offer?: T, propertyName?: keyof T, value?: V) => void, isNew: boolean, validate: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {
        throw new Error(`getSubmissionFormFields not defined in ${typeof this}`);
    }

    exportForSubmission(updateSubmissionDate: boolean = true, exportComments: boolean = true): any {
        throw new Error(`exportForSubmission not defined in ${typeof this}`);
    }

    getShipDateOverride() {
        throw new Error(`getShipDateOverride not defined in ${typeof this}`);
    }

    getApprovalNotice() {
        throw new Error(`getApprovalNotice not defined in ${typeof this}`);
    }

    getComments() {
        throw new Error(`getComments not defined in ${typeof this}`);
    }

    isInApproval() {
        throw new Error(`isInApproval not defined in ${typeof this}`);
    }

    getVisibleProductDetails() {
        throw new Error(`getVisibleProductDetails not defined in ${typeof this}`);
    }

    getSubmissionStatusDetails() {
        throw new Error(`getSubmissionStatusDetails not defined in ${typeof this}`);
    }

    getSubmissionPartnerTestingStatusDetails(isFirstParty: boolean) {
        throw new Error(`getSubmissionStatusDetails not defined in ${typeof this}`);
    }

    getTestReports() {
        throw new Error(`getTestReports not defined in ${typeof this}`);
    }

    getActionButton(isFirstParty: boolean, onPartnerRequestResubmissionCallback: React.MouseEventHandler<HTMLElement>) {
        throw new Error(`getActionButton not defined in ${typeof this}`);
    }

    /* offers with their own Purchasable property will override this property */
    getPurchasableStatus() {
        return true;
    }

    getTakedownButton(parentCallback: () => void) {
        throw new Error(`getTakedownButton not defined in ${typeof this}`);
    }

    getIngestButton(parentCallback: () => void) {
        throw new Error(`getIngestButton not defined in ${typeof this}`);
    }

    getPartnerTestingButtons(isFirstParty: boolean, onPartnerTestingCallback: (state?: string) => void) {
        throw new Error(`getPartnerTestingButtons not defined in ${typeof this}`);
    }

    getReadyToIngestButton(isFirstParty: boolean, onReadyToIngestCallback: React.MouseEventHandler<HTMLElement>) {
        throw new Error(`getReadyToIngestButton not defined in ${typeof this}`);
    }

    getCarryOverSignOffState(onCarryOverSignoffState: (signOffState?: MisSignOffState) => void) {
        throw new Error(`getCarryOverSignOffState not defined in ${typeof this}`);
    }

    getCarryOverSignOffConfirmation(newSignOffState: MisSignOffState | undefined, onCarryOverSignOffStateConfirmation: (confirmation: boolean) => void) {
        throw new Error(`getCarryOverSignOffConfirmation not defined in ${typeof this}`);
    }

    convertToDisplayField<V>(productDetails: DisplayField<V>[], offerField: OfferField<V>, url?: string) {
        offerField.convertToDisplayField(productDetails, url)
    }

    static get VSO_STATES() {
        return VSO_STATES;
    }

    static get VSO_STATE_INFO() {
        return VSO_STATE_INFO;
    };

    static get VSO_PARTNER_TESTING_STATES() {
        return VSO_PARTNER_TESTING_STATES;
    }

    static get VSO_PARTNER_TESTING_STATE_INFO() {
        return VSO_PARTNER_TESTING_STATE_INFO;
    };
}