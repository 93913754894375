import { MarketplaceItem, DisplayField, OfferField, visibleToOfferAndMetaWorkFlow } from './MarketplaceItem';
import { AUGER_OFFER_TYPES, BUTTON_ACTION, BUTTON_STATE, BUTTON_TEXT, VSO_STATES, VSO_PARTNER_TESTING_STATES, MAX_UPLOAD_SIZE, READABLE_VSO_STATES, VSO_STATE_INFO } from '../../Constants/enums';
import React, { CSSProperties } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FormatAsRow, CreateDeeplink, FormatDateTime, getLang, VersionLessThanOrEqualTo, VersionCompare } from '../../Constants/utils';
import { TextFormField, TextAreaFormField, DropDownFormField, FileFormField, ValidationFormField, FormFieldValidationResult, ValidationReport } from '../../Components/FormFields/FormField'
import { VALIDATION_DECISIONS } from '../../Constants/enums'
import { WORK_FLOW_TYPES } from '../../Constants/enums';
import { GetAllSubmissions } from '../../Api/WebApi'
import { Publisher } from '../Publisher';
import { Developer } from '../Developer';
import * as styles from '../../Constants/styles';
import { MisSignOffState } from './MarketplaceDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward } from '@fortawesome/free-solid-svg-icons';
import { DropdownSelect } from '../../Components/DropdownSelect';

export enum PackTypes {
    NONE = "Please Select a Content Type",
    AIRCRAFT = "Aircraft",
    AIRPORT = "Airport",
    MISSION = "Mission",
    LIVERY = "Livery",
    SCENERY = "Scenery",
    MISC = "Misc",
    INSTRUMENTS = "Instruments",
    BUNDLE = "Bundle",
    PACK = "Pack"
}

export class DurableOfferModel extends MarketplaceItem {
    cardId = new OfferField<string>("Card ID", true);
    cardState = new OfferField<string>("Card State", true);
    cardTitle = new OfferField<string>("Card Title", true);
    carryOverContentStatus = new OfferField<string>("Carryover Content Status", false);
    carryOverMarketplaceStatus = new OfferField<string>("Carryover Marketplace Status", false);
    carryOverSignOffState = new OfferField<MisSignOffState>("Carryover Sign Off State", false);
    offerId = new OfferField<string>("Offer ID", true);
    offerType = new OfferField<string>("Offer Type", true, AUGER_OFFER_TYPES.DURABLE_OFFER);
    offerTitle = new OfferField<string>("Offer Title", true);
    workItemType = new OfferField<string>("Work Item Type", false);
    isAnUpdate = new OfferField<boolean>("IsAnUpdate", true);
    lastModified = new OfferField<string>("Last Modified Date", true);
    localizationStatus = new OfferField<string>("Localization Status", false);
    currentGameVersion = new OfferField<string>("Current Game Version", false);
    comments = new OfferField<string[]>("Comments", true, []);
    relations = new OfferField<string []>("Relations", true, []);
    archived = new OfferField<boolean>("Archived", false);
    binaryFile = new OfferField<File>("Binary File", false);
    binaryLocation = new OfferField<string>("Binary Location", true);
    contentCategory = new OfferField<string>("Content Category", false);
    pipelineRunId = new OfferField<string>("Cooker Pipeline ID", true);
    packType = new OfferField<string>("Pack Type", true);
    creatorId = new OfferField<string>("Creator ID", true);
    creatorName = new OfferField<string>("Creator Name", true);
    devProductId = new OfferField<string>("Dev Product ID", false);
    fileName = new OfferField<string>("File Name", true);
    firstSubmittedDate = new OfferField<string>("First Submitted Date", true);
    firstSubmittedBy = new OfferField<string>("First Submitted By", true);
    ingestedVersion = new OfferField<string>("Ingested Version", false);
    ingestionPlatforms = new OfferField<string>("Ingestion Platforms", true);
    initialReleaseDate = new OfferField<string>("Initial Release Date", false);
    manifestTitle = new OfferField<string>("Manifest Title", false);
    marketplaceCreator = new OfferField<string>("Marketplace Creator", true);
    marketplaceReleaseOwner = new OfferField<string>("Marketplace Release Owner", true);
    marketplacePlatforms = new OfferField<string>("Platforms", true);
    offerPrice = new OfferField<string>("Offer Price", true);
    lastSubmittedDate = new OfferField<string>("Last Submitted Date", true);
    lastSubmittedBy = new OfferField<string>("Last Submitted By", true);
    lastUpdateReleased = new OfferField<string>("Last Update Released", true);
    logComment = new OfferField<string>("Log Comment", true);
    minimumGameVersion = new OfferField<string>("Minimum Game Version", true);
    packageName = new OfferField<string>("Package Name", true);
    partnerPriority = new OfferField<string>("Partner Priority", true);
    partnerTestingState = new OfferField<string>("Partner Testing State", true, "Not Started");
    prioritySubmittedDate = new OfferField<string>("Priority Submitted Date", true);
    purchasable = new OfferField<boolean>("Publish Status", false, true);
    readyToIngest = new OfferField<boolean>("Ready To Ingest", true);
    releasedVersion = new OfferField<string>("Released Version", false);
    retailProductId = new OfferField<string>("Retail Product ID", false);
    shortCreatorName = new OfferField<string>("Short Creator Name", true);
    developerId = new OfferField<string>("Developer Id", true);
    submittedVersion = new OfferField<string>("Submitted Version", true);
    tempLinkedItem = new OfferField<string>("Temp Linked Item", true)
    testReports = new OfferField<string>("Test Reports", false);
    validationResult = new OfferField<ValidationReport>("Validation Result", false);
    vsoTags = new OfferField<string>("VSO Tags", true);
    wasmFunctionality = new OfferField<string>("WASM Functionality", true);
    wasmRequired = new OfferField<string>("WASM Required", true);
    workItemPlatform = new OfferField<string>("Work Item Platform", true);

    submissions:any[] = [];
    linkedSubmission: DurableOfferModel | null | undefined = null;

    constructor(data: any) {
        super(data);
        this.offerId.value = data.offerId;

        if (!this.offerId.value && data.cardId) {
            this.offerId.value = data.cardId;
        }

        for (var prop in this) {
            if (data.hasOwnProperty(prop)) {
                if (this.hasOwnProperty(prop)) {
                    const offerField = this[prop] as OfferField<any>;

                    offerField.value = data[prop];
                    offerField.originalValue = data[prop];
                } else {
                    console.log(`Can't find prop "${prop}" in ${this.offerType.value} model!`, this);
                    throw new Error(`Can't find prop "${prop}" in ${this.offerType.value} model!`);
                }
            }
        }

        this.logComment.value = "";
        
        if (this.offerPrice.value) {
            let price = parseFloat(this.offerPrice.value as string);

            this.offerPrice.value = price.toFixed(2);
        }

        // if (this.cardState.value == null) {
        //     this.cardState.value = DurableOfferModel.VSO_STATES.PLANNED;
        // }

        this.offerTitle.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }

            let matchingSubmission = durableOffer.submissions?.find(s => s.offerTitle === value && s.creatorName === durableOffer.creatorName.value && s.cardId !== durableOffer.cardId.value && s.cardId !== durableOffer.tempLinkedItem.value);
            let needsICAO = false;

            if (durableOffer.packType.value !== PackTypes.BUNDLE && durableOffer.packType.value !== PackTypes.PACK && (durableOffer.contentCategory.value as string)?.toLowerCase().includes('airport')) {
                needsICAO = /^[A-Z0-9]{3,5} [^- ].+/.test(value) === false;
            }

            const icaoWarning = "Airport titles should start with their ICAO (with no dash afterwards). Please correct the title in the package to avoid this error on subsequent submissions.";
            const titleMismatch = durableOffer.manifestTitle.value !== null && value !== durableOffer.manifestTitle.value;

            return {
                result: needsICAO || value.length > 50 || value.length <= 2 || value.trim().length !== value.length || matchingSubmission ? 'error' : titleMismatch || (originalValue && value !== originalValue) ? 'warning' : 'success',
                message: needsICAO ? icaoWarning : value.length > 50 ? "Title too long." : value.length <=2 ? "Title too short." : value.trim().length !== value.length ? "Enter valid title." : matchingSubmission ? "Work item already exists with the same title." : titleMismatch ? "Marketplace title does not match the the manifest." : originalValue && value !== originalValue ? "Title has changed since the previous submission." : "",
                requestConfirmation: true
            }
        } 

        this.shortCreatorName.validate = function (durableOffer?: DurableOfferModel, value?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }

            const publishers = durableOffer.auth.getPublishers() as Publisher[];

            return {
                result: durableOffer.auth.isInternal() || publishers?.find(publisher => value === publisher.id) ? 'success' : 'error',
                message: durableOffer.auth.isExternal() && !publishers?.find(publisher => value === publisher.id) ? "Invalid short name." : ""
            }
        } 

        this.packageName.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }

            let packageNameStartsWithShortName = value.startsWith(durableOffer.shortCreatorName.value as string);
            let matchingSubmission = durableOffer.submissions?.find(s => s.packageName === value && s.cardId !== durableOffer.cardId.value && s.cardId !== durableOffer.tempLinkedItem.value) !== undefined;
            let packageNameNotFoundError = 'Package name not found. Please verify you are using the latest SDK, do a "Clean All", then "Build & Export" and choose "Flight Simulator Marketplace."'

            return {
                result: (!originalValue || originalValue === '' || value === originalValue) && value.length <= 100 && value.length > 2 && packageNameStartsWithShortName && (matchingSubmission === false) ? 'success' : 'error',
                message : value.length > 100 ? "Package name too long." : value.length === 0 ? packageNameNotFoundError : value.length <=2 ? "Package name too short." : value.trim().length !== value.length ? "Enter valid package name." : (packageNameStartsWithShortName === false) ? "Package name must start with short name" :  matchingSubmission ? "Work item already exists with the same package name." : "Package name for a resubmission or update must be the same as the previous submission. Please correct the package name and resubmit."
            };
        } 

        this.creatorName.validate = function (durableOffer?: DurableOfferModel, value?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }
            
            const publishers = durableOffer.auth.getPublishers() as Publisher[];

            return {
                result: (durableOffer.auth.isInternal() || publishers?.find(publisher => value === publisher.name)) ? 'success' : 'error',
                message: durableOffer.auth.isExternal() && !publishers?.find(publisher => value === publisher.name) ? "Invalid creator name." : ""
            }
        }

        this.marketplaceCreator.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }
            
            return {
                result: !value ? 'error' : originalValue && value !== originalValue ? 'warning' : 'success',
                message: originalValue && value !== originalValue ? "Marketplace Creator has changed since the previous submission." : !value ? "Invalid creator name." :  "",
                requestConfirmation: true
            }
        }

        const checkMinimumGameVersion = (offer: DurableOfferModel, packageGameVersion: string[], originalGameVersion: string[] | undefined, currentGameVersion: string[], allowedGameVersions: string[]): { allowed: boolean, result: FormFieldValidationResult } => {
            const allowedVersion = offer.packType.value === PackTypes.BUNDLE || VersionLessThanOrEqualTo(packageGameVersion, currentGameVersion);
            let disallowedVersion = false;
            let olderVersion = originalGameVersion && !VersionLessThanOrEqualTo(originalGameVersion, packageGameVersion);

            for (let versionIndex = 1; versionIndex < allowedGameVersions.length; versionIndex++) {
                disallowedVersion = disallowedVersion || new RegExp(allowedGameVersions[versionIndex]).test((offer.packType.value as string)?.toLowerCase() + ":" + packageGameVersion);
            }

            return {
                allowed: allowedVersion && !disallowedVersion && !olderVersion,
                result: {
                    result: 'error',
                    message: !allowedVersion ? "Minimum game version is higher than the current game version (" + allowedGameVersions[0] + ")." :
                            disallowedVersion ? "We are not currently accepting submissions of this content type for this game version. Please contact your content manager or email msfsmcp@microsoft.com for further information regarding acceptable versions for this content type." :
                            olderVersion ? "Minimum game version must be the same or greater than the previous submission." :""
                }
            }
        };

        this.minimumGameVersion.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value || !durableOffer?.packType.value) {
                return {
                    result: null
                }
            }
            
            const allowedGameVersions = (durableOffer.currentGameVersion.value as string)?.split(';');
            const currentGameVersion = allowedGameVersions[0].split('.');

            if (durableOffer.packType.value === PackTypes.PACK) {
                const originalGameVersions = (originalValue as string)?.split(';');

                const splitOriginalGameVersions = originalGameVersions?.map(value => {
                    const splitValue = value.split(':');
                    return splitValue?.[splitValue?.length - 1];
                });
                
                splitOriginalGameVersions?.sort(VersionCompare);

                const { allowed, result} = checkMinimumGameVersion(durableOffer, value.split('.'), splitOriginalGameVersions?.[0]?.split('.'), currentGameVersion, allowedGameVersions);

                if (!allowed) {
                    return result;
                }
            } else {
                const { allowed, result } = checkMinimumGameVersion(durableOffer, value.split('.'), (originalValue as string)?.split('.'), currentGameVersion, allowedGameVersions);

                if (!allowed) {
                    return result;
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : 'success',
                message: originalValue && value !== originalValue ? "Minimum game version has changed since the previous submission" : ""
            }
        }

        this.creatorId.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }
            
            const publishers = durableOffer.auth.getPublishers() as Publisher[];
            const isNumeric = /^\+?\d+$/.test(value);

            return (
                isNumeric === false ? 
                {
                    result: 'error',
                    message: "Non-numeric value."
                } : 
                value.length !== 8 ?
                {
                    result: 'error',
                    message: "Id should be 8 digits long."
                } : 
                (durableOffer.auth.isExternal() && !publishers?.find(publisher => value === publisher.sellerId)) ?
                {
                    result: 'error',
                    message: "Invalid seller id."
                } :
                (originalValue && value !== originalValue) ?
                {
                    result: 'error',
                    message: "Seller id for a resubmission or update must be the same as the previous submission. Please correct the seller id and resubmit."
                } :
                {
                    result: 'success'
                }
            );
        }

        this.submittedVersion.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }
            
            return {
                result: (!durableOffer.validationResult.value || !originalValue || originalValue === '' || value !== originalValue) ? 'success' : 'warning',
                message : "Version has not changed since the previous submission."
            };
        }

        this.packType.validate = function (durableOffer?, value?, originalValue?) {
            if (value === PackTypes.NONE || !durableOffer.validationResult.value) {
                return {
                    result: null
                }
            }
            
            const validPackType = Object.values(PackTypes).includes(value as any);

            return {
                result: originalValue && value !== originalValue ? 'warning' : validPackType ? 'success' : 'error',
                message: originalValue && value !== originalValue ? 'Content Type has changed since the previous submission.' : value === PackTypes.NONE ? 'Please select a pack type.' : !validPackType ? 'Unknown pack type.' : ''
            };
        }

        this.wasmFunctionality.validate = function (durableOffer?, value?, originalValue?) {
            if (durableOffer.wasmRequired.value === "No" || !(durableOffer.ingestionPlatforms.value as string)?.toLowerCase().includes("xbox")) {
                return {
                    result: 'success'
                }
            } 

            if (value == null || !durableOffer.validationResult.value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : value.length > 10000 || value.length === 0 ? 'error' : 'success',
                message: value.length > 10000 ? "10000 character limit exceeded." : value.length === 0 ? "WASM files detected in package. Please outline how this content uses WASM -- i.e., what features/functionality it enables." : ''
            };
        }

        this.wasmRequired.validate = function (durableOffer?, value?, originalValue?) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }
            
            return {
                result: originalValue && value !== originalValue ? 'warning' : 'success',
                message: 'WASM requirement has changed since the previous submission.'
            };
        }

        this.offerPrice.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }

            const valueAsNumber = parseFloat(value);

            return {
                result: valueAsNumber < 0 || valueAsNumber >= 200 ? 'error' : (originalValue && value !== originalValue) || (valueAsNumber >= 0 && valueAsNumber < 4.99) ? 'warning' : 'success',
                message: valueAsNumber < 0 || valueAsNumber >= 200 ? "Price should be between $4.99 and $199.99." : originalValue && value !== originalValue ? 'Price has changed since the previous submission.' : valueAsNumber >= 0 && valueAsNumber < 4.99 ? "Price less than $4.99 may result in ingestion failure." : "",
                requestConfirmation: true
            };
        }

        this.ingestionPlatforms.validate = function (durableOffer?: DurableOfferModel, value?: string, originalValue?: string, enablePCAndXbox?: boolean, enablePCOnly?: boolean, enableXboxOnly?: boolean) {
            if (!value || !durableOffer?.validationResult.value) {
                return {
                    result: null
                }
            }

            let pcCardState = durableOffer.workItemPlatform.value === 'PC' ? durableOffer.cardState.value : durableOffer.linkedSubmission?.workItemPlatform.value === 'PC' ? durableOffer.linkedSubmission.cardState.value : undefined;
            let xboxCardState = durableOffer.workItemPlatform.value === 'Xbox' ? durableOffer.cardState.value : durableOffer.linkedSubmission?.workItemPlatform.value === 'Xbox' ? durableOffer.linkedSubmission.cardState.value : undefined;

            let pcUpdateable = pcCardState ? VSO_STATE_INFO[pcCardState as string].updateable : true;
            let xboxUpdateable = xboxCardState ?  VSO_STATE_INFO[xboxCardState as string].updateable : true;

            return {
                result: (value === 'PC & Xbox' && !(pcUpdateable && enablePCAndXbox)) || (value === 'PC Only' && !(pcUpdateable && enablePCOnly)) || (value === 'Xbox Only' && !(xboxUpdateable && enableXboxOnly && pcCardState)) ? 'error' : (originalValue && value !== originalValue) ? 'warning' : 'success',
                message: value === 'PC & Xbox' && !enablePCAndXbox ? "Please select a PC or Xbox only package." :
                         value === 'PC Only' && !enablePCOnly ? "Please select a package for the Xbox." :
                         value === 'Xbox Only' && !enableXboxOnly ? "Please select a package for the PC." :
                         value.includes('PC') && !pcUpdateable ? "PC content cannot be updated at this time." :
                         value.includes('Xbox') && !xboxUpdateable ? "Xbox cannot be updated at this time." :
                         value === 'Xbox Only' && !pcCardState ? "Please submit the PC version before submitting Xbox." :
                         originalValue && value !== originalValue ? "Ingestion platform(s) has changed since the previous submission." : ""
            };
        }

        if (this.auth) {
            GetAllSubmissions(this.auth, this.offerType.value).then((result) => this.setSubmissions(result)).catch((error) => {
                console.log("error", error);
            })
        }
    }

    setSubmissions(result: { count: number, value: any }): void {
        if (result.count === 0) {
            console.log("No Offers Found");
            this.submissions = [];
        } else {
            this.submissions = result.value;
        }
    }

    exportForSubmission(updateSubmissionDate: boolean = true, exportComments: boolean = true, tags?: string): any {
        // if (this.submittedVersion.value) {
        //     this.submittedVersion.value = ResolveNextVersion(this.submittedVersion.value, this.cardState.value == VSO_STATES.CLOSED);
        // } else {
        //     this.submittedVersion.value = "1.0.0";
        // }

        var exportObject = super.export();

        if (tags) {
            if (exportObject.vsoTags) {
                exportObject.vsoTags = Array.from(new Set([...exportObject.vsoTags.split(','), ...tags.split(',')])).join(',');
            } else {
                exportObject.vsoTags = tags;
            }
        }

        if (this.validationResult.value) {
            exportObject.submissionId = (this.validationResult.value as ValidationReport).inputMetadata.submissionId;
        }

        if (!exportObject.developerId) {
            exportObject.developerId = exportObject.shortCreatorName;
        }

        if (exportComments) {
            exportObject.logComment = this.getLogComment();
        }

        if (updateSubmissionDate) {
            exportObject.readyToIngest = (exportObject.packType === PackTypes.BUNDLE || exportObject.packType === PackTypes.PACK || exportObject.wasmRequired === 'Yes') ? true : false;
            
            let previousLastSubmittedDate = exportObject.lastSubmittedDate;

            exportObject.lastSubmittedDate = new Date().toISOString();

            if (!exportObject.prioritySubmittedDate || exportObject.prioritySubmittedDate === previousLastSubmittedDate) {
                exportObject.prioritySubmittedDate = exportObject.lastSubmittedDate;
            }

            exportObject.lastSubmittedBy = this.auth.getUserName();
            exportObject.binaryLocation = `${exportObject.shortCreatorName}/${exportObject.packageName}/${exportObject.lastSubmittedDate.replaceAll(':', '_')}/`;

            if (this.binaryFile.value) {
                exportObject.binaryName = `${exportObject.binaryLocation}${(this.binaryFile.value as File).name}`;
                exportObject.fileName = (this.binaryFile.value as File).name;
            }
        }

        if (exportObject.cardState) {
            if (updateSubmissionDate) {
                if (exportObject.cardState === VSO_STATES.PLANNED || exportObject.cardState === VSO_STATES.RELEASED) {
                    exportObject.firstSubmittedDate = exportObject.lastSubmittedDate;
                    exportObject.prioritySubmittedDate = exportObject.lastSubmittedDate;
                    exportObject.firstSubmittedBy = exportObject.lastSubmittedBy;
                }
                if (exportObject.lastUpdateReleased) {
                    exportObject.isAnUpdate = true;
                }
                exportObject.cardState = DurableOfferModel.VSO_STATE_INFO[exportObject.cardState].newstate;
            }
        } else {
            exportObject.cardState = DurableOfferModel.VSO_STATES.PLANNED;
            exportObject.firstSubmittedDate = exportObject.lastSubmittedDate;
            exportObject.firstSubmittedBy = exportObject.lastSubmittedBy;
        }

        exportObject.workflowType = this.workflowType.value;

        return exportObject;
    }

    getLogComment(includeVersion: boolean = true) {
        if (includeVersion) {
            var commentType = "Change Log";

            if (!this.cardState.value || this.cardState.value === VSO_STATES.PLANNED) {
                commentType = "Submission Notes";
            }

            let logComment = "<div class='changelog-" + this.submittedVersion.value + "'><strong>Version " + this.submittedVersion.value + " " + commentType + " </strong>\n" + this.logComment.value + "</div>";
            logComment = logComment.replace(/\n/g, "<br>");
            return logComment;
        } else {
            let logComment = "<div class='changelog'>" + this.logComment.value + "</div>";
            logComment = logComment.replace(/\n/g, "<br>");
            return logComment;
        }
    }

    getTitle() {
        return this.cardTitle;
    }

    getTestReports() {
        return this.testReports.value;
    }

    static getReadableModelName() {
        return "Durable";
    }

    getSubmissionStatusDetails() {
        var productDetails: DisplayField<string>[] = [];

        var VSO_STATE_INFO = DurableOfferModel.VSO_STATE_INFO;

        super.convertToDisplayField(productDetails, new OfferField("Status", false, VSO_STATE_INFO[this.cardState.value as string].status));
        super.convertToDisplayField(productDetails, this.submittedVersion);
        super.convertToDisplayField(productDetails, this.ingestedVersion);

        if (this.isAnUpdate.value || this.cardState.value === VSO_STATES.RELEASED) {
            super.convertToDisplayField(productDetails, this.releasedVersion);
        }
        
        super.convertToDisplayField(productDetails, this.firstSubmittedBy);
        super.convertToDisplayField(productDetails, this.lastSubmittedBy);
        var formattedDate = FormatDateTime(this.lastSubmittedDate.value);
        super.convertToDisplayField(productDetails, new OfferField("Submitted On", false, formattedDate));

        return productDetails.map(FormatAsRow);
    }

    getSubmissionPartnerTestingStatusDetails(isFirstParty: boolean) {
        var VSO_PARTNER_TESTING_STATE_INFO = DurableOfferModel.VSO_PARTNER_TESTING_STATE_INFO;
        
        if (VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTING || VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTED) {
            if (!isFirstParty && VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].altStatus) {
                return (
                    <div>
                        <h2 className='text-center' style={VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].style as CSSProperties}>{VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].status}</h2>
                        {<h4 className='text-center' style={VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].altStyle as CSSProperties}>{VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].altStatus}</h4>}
                    </div>
                );
            } else {
                return (
                    <h2 className='text-center' style={VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].style as CSSProperties}>{VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].status}</h2>
                );
            }
        } else {
            return null;
        }
    }

    getApprovalNotice() {
        var VSO_STATES = DurableOfferModel.VSO_STATES;

        if (this.cardState.value === VSO_STATES.PARTNER) {
            return <div>
                <p className="action bold">Action Required</p>
                <p className="action">Please address any issues and submit new content.</p>
            </div>
        } else {
            return "";
        }
    }

    isInApproval() {
        var VSO_STATES = DurableOfferModel.VSO_STATES;

        if (this.cardState.value === VSO_STATES.PARTNER) {
            return true;
        } else {
            return false;
        }
    }

    getSubmissionFormFields(formInfoCallback: <T extends MarketplaceItem, V>(offer?: T, propertyName?: keyof T, value?: V) => void, isNew: boolean, validate: boolean, enablePCAndXbox: boolean, enablePCOnly: boolean, enableXboxOnly: boolean, context?: any): JSX.Element {

        const workflowType: string = this.workflowType.value as string;
        const formFields = [];
        const publishers = this.auth.getPublishers() as Publisher[];
        const developers = this.auth.getDevelopers() as Developer[];
        const developerOptions = developers?.map((developer) => { return { option: developer.id, disabled: false } }).concat(publishers?.map((publisher) => { return { option: publisher.id, disabled: false } }));

        formFields.push(<TextFormField
            key={"offerTitle"}
            context={this}
            value={this.offerTitle.value ?? ""}
            title={"Title"}
            placeholder={"Title"}
            helpContent={"Extracted from package."}
            propertyName={"offerTitle"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.offerTitle.validate} />);
            
        formFields.push(<TextFormField
            key={"shortCreatorName"}
            context={this}
            value={this.shortCreatorName.value ?? ""}
            title={"Publisher Id"}
            placeholder={"Publisher Id"}
            helpContent={"Extracted from package."}
            propertyName={"shortCreatorName"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.shortCreatorName.validate} />);

        formFields.push(<DropDownFormField
            key={"developerId"}
            context={this}
            value={this.developerId.value ?? this.shortCreatorName.value ?? ""}
            title={"Developer Id"}
            placeholder={"Developer Id"}
            helpContent={"Extracted from package."}
            propertyName={"developerId"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={!isNew}
            offerUndo={false}
            allowCustomValue={true}
            validate={validate}
            validateCallback={this.developerId.validate}
            optionsList={developerOptions} />);

        formFields.push(<TextFormField
            key={"packageName"}
            context={this}
            value={(this.packageName.value === null) ? '' : this.packageName.value}
            title={"Package Name"}
            placeholder={"Package Name"}
            helpContent={"Extracted from package."}
            propertyName={"packageName"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.packageName.validate} />);

        formFields.push(<TextFormField
            key={"creatorName"}
            context={this}
            value={this.creatorName.value}
            title={"Submitting Creator"}
            helpContent={"Extracted from package."}
            placeholder={"Submitting Creator"}
            propertyName={"creatorName"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.creatorName.validate} />);

        formFields.push(<TextFormField
            key={"marketplaceCreator"}
            context={this}
            value={this.marketplaceCreator.value}
            title={"Marketplace Creator"}
            helpContent={"Extracted from package."}
            placeholder={"Marketplace Creator"}
            propertyName={"marketplaceCreator"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.marketplaceCreator.validate} />);

        formFields.push(<TextFormField
            key={"creatorId"}
            context={this}
            value={this.creatorId.value}
            title={"Seller Id"}
            placeholder={"Seller Id"}
            helpContent={"Extracted from package."}
            propertyName={"creatorId"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.creatorId.validate} />);

        formFields.push(<TextFormField
            key={"submittedVersion"}
            context={this}
            value={this.submittedVersion.value}
            title={"Submission Version"}
            placeholder={"0.0.0"}
            helpContent={"Extracted from package."}
            propertyName={"submittedVersion"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.submittedVersion.validate} />);

        // var purchasableRadioFields = [{
        //     name: "Available",
        //     value: true,
        //     description: "This offer is active and can be purchased by users."
        // },{
        //     name: "Delisted",
        //     value: false,
        //     description: "This offer has been Delisted."
        // }]


        this.purchasable.validate = () => { return { result: 'success'} };

        // if (shouldDisplayFormField(this.purchasable, isNew, workflowType)) {
        //     formFields.push(<GenericRadioForm
        //         key={"purchasable"}
        //         value={!!this.purchasable.value}
        //         title={this.purchasable.displayName}
        //         disabled={true}
        //         useModal={false}
        //         radioFields={purchasableRadioFields}
        //         radioGroupName={"purchasableRadioGroup"}
        //         popoverContent={<div>
        //             <p>New and updated offers are always purchasable.</p>
        //             <p>Take down requests for published content set Publish Status to Delisted and the offer is removed from the store. Users who purchased this offer previously will retain ownership in their inventory but it will not be searchable or visible anywhere in the store. Content take downs follow the normal update process on Tuesdays and Fridays.</p>
        //             <p>Offers that have been Delisted must be resubmitted if you want them to be available again.</p>
        //         </div>}
        //         placeholder={""}
        //         helpContent={<span style={{ fontSize: 15 }}></span>}
        //         propertyName={"purchasable"}
        //         isNew={true}
        //         returnCallback={formInfoCallback}
        //         warning={""}
        //         validateCallback={this.purchasable.validate} />);
        // }

        formFields.push(<TextFormField
            key={"offerPrice"}
            context={this}
            value={this.offerPrice.value ?? ""}
            title={"Price in USD"}
            placeholder={"9.99"}
            helpContent={"Extracted from package."}
            isNew={isNew}
            disabled={true}
            propertyName={"offerPrice"}
            returnCallback={formInfoCallback}
            offerUndo={false}
            validate={validate}
            validateCallback={this.offerPrice.validate} />)

        var offerTypeOptions = Object.entries(PackTypes).map(([key, value]) => {
            return {
                option: value,
                disabled: false
            };
        });

        formFields.push(<DropDownFormField
            key={"packType"}
            context={this}
            value={(this.packType.value === null) ? PackTypes.NONE : this.packType.value}
            title={"Content Type"}
            isNew={isNew}
            disabled={true}
            helpContent={"Extracted from package."}
            propertyName={"packType"}
            returnCallback={formInfoCallback}
            offerUndo={false}
            validate={validate}
            validateCallback={this.packType.validate}
            optionsList={offerTypeOptions} />)

        if (this.wasmRequired.value === "Yes" && (this.ingestionPlatforms.value as string)?.toLowerCase().includes("xbox")) {
            formFields.push(<TextAreaFormField
                key={"wasmFunctionality"}
                context={this}
                value={this.wasmFunctionality.value}
                title={"WASM Functionality"}
                popoverContent={<div><p>WASM files detected in package. Please outline how this content uses WASM -- i.e., what features/functionality it enables.</p></div>}
                placeholder={"- Implements startup from cold-and-dark\r\n- Not critical but useful in true-to-life scenarios."}
                helpContent={"10000 character limit"}
                propertyName={"wasmFunctionality"}
                isNew={isNew}
                returnCallback={formInfoCallback}
                validate={validate}
                validateCallback={this.wasmFunctionality.validate} />)
        }

        formFields.push(<TextFormField
            key={"minimumGameVersion"}
            context={this}
            value={this.minimumGameVersion.value}
            title={"Minimum Game Version"}
            placeholder={"1.0.0"}
            helpContent={"Extracted from package."}
            propertyName={"minimumGameVersion"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.minimumGameVersion.validate} />);
            
        formFields.push(<DropDownFormField
            key={"ingestionPlatforms"}
            context={this}
            value={(this.ingestionPlatforms.value === null) ? '' : this.ingestionPlatforms.value}
            title={this.ingestionPlatforms.displayName}
            optionsList={[{option: "PC & Xbox", disabled: !enablePCAndXbox}, {option: "PC Only", disabled: !enablePCOnly}, {option: "Xbox Only", disabled: !enableXboxOnly}]}
            placeholder={"Xbox"}
            helpContent={"Extracted from package. Note: Overriding this here will not modify the package but will determine which platform this package is ingested for."}
            propertyName={"ingestionPlatforms"}
            returnCallback={formInfoCallback}
            isNew={isNew}
            enablePCAndXbox={enablePCAndXbox}
            enablePCOnly={enablePCOnly}
            enableXboxOnly={enableXboxOnly}
            disabled={true}
            offerUndo={false}
            validate={validate}
            validateCallback={this.ingestionPlatforms.validate} />);
            
        // if (this.packType.value === PACK_TYPES.AIRCRAFT) {
        //     this.logComment.validate = function (value) {
        //         return (value.length <= 10000 && value.length >= 0) ? 'success' : 'error';
        //     }
        // } else {
        //     this.logComment.validate = function (value) {
        //         return (value.length <= 10000 && value.length > 5) ? 'success' : 'error';
        //     }
        // }

        formFields.push(<TextAreaFormField
            key={"logComment"}
            context={this}
            value={''}
            title={(isNew) ? "Submission Notes" : "Change Log"}
            popoverContent={<div><p>Every submission you must detail the changes that you made. Please include the bugs you addressed, any title/description/price changes, content changes, and anything you think would be helpful for us to know.</p>
                <p>We use this data to add context so we can know exactly how to handle your submission. </p></div>}
            placeholder={"- Adjusted dash layout\r\n   - Increased size of main instruments\r\n   - Changed font on transponder\r\n- Fixed WASM bug in engine startup sequence"}
            helpContent={"10000 character limit"}
            propertyName={"logComment"}
            isNew={true}
            returnCallback={formInfoCallback}
            validate={validate}
            validateCallback={this.logComment.validate} />)

        var showBinary = true;
        if (!isNew && workflowType !== WORK_FLOW_TYPES.OFFER) { showBinary = false }
        //if (this.packType.validate(this.packType.value) !== 'success') { showBinary = false }

        if (!showBinary) {
            this.binaryFile.value = undefined;
            this.validationResult.value = undefined;
            this.validationResult.validate = undefined;
        }
        else {
            this.binaryFile.validate = function (offer?, value?) {
                const file = value as File;
                
                if (file) {
                    if (file.size < (DurableOfferModel.MAX_FILE_SIZE)) {
                        var extension = file.name.split('.').pop();
                        if (extension === "zip") {
                            //console.log("success on BinaryValidation", file)
                            return {
                                result: "success"
                            };
                        }
                        else {
                            return {
                                result: "error"
                            };
                        }
                    }
                }
                //console.log("error on BinaryValidation")
                return {
                    result: null
                }
            }

            var sizeInMb = DurableOfferModel.MAX_FILE_SIZE / 1024 / 1024;
            formFields.push(<FileFormField
                key={"binaryFile"}
                context={this}
                title={"Attach File"}
                popoverContent={<p>Please follow the steps outlined <a target="_blank" rel="noopener noreferrer" href="https://docs.flightsimulator.com/html/Developer_Mode/Project_Editor/Export_Window.htm">here</a> to generate a package for submission..</p>}
                helpContent={`Upload a zip with a maximum file size of ${sizeInMb} MB`}
                propertyName={"binaryFile"}
                isNew={true}
                inputKey={(this.packType.value) ? this.packType.value as string | number : "null"}
                returnCallback={formInfoCallback}
                validate={validate}
                validateCallback={this.binaryFile.validate} />)

            this.validationResult.validate = function (offer?, value?) {
                const results = value as ValidationReport;

                if (results) {
                    //console.log("validatoinResults", results);
                    if (results.decision === VALIDATION_DECISIONS.PASS || results.decision === VALIDATION_DECISIONS.PASS_WITH_WARNING) {
                        return {
                            result: "success"
                        };
                    }
                    else if (results.decision === VALIDATION_DECISIONS.PENDING) {
                        return {
                            result: "warning",
                            message: "Validation is pending. Please wait for the validation to complete before submitting.",
                            blockSubmission: true
                        }
                    }
                    else {
                        return {
                            result: "error",
                            message: "Validation failed. Please correct the issues and resubmit.",
                        };
                    }
                } else {
                    return {
                        result: null
                    }
                }
            }

            this.validationResult.validate = this.validationResult.validate?.bind(this);

            formFields.push(<ValidationFormField
                key={"validationResult"}
                context={this}
                game={"fs20"}
                title={"Validation Results"}
                placeholder={""}
                helpContent={`Your content must pass validation in order to submit.`}
                propertyName={"validationResult"}
                isNew={true}
                auth={this.auth}
                binaryFile={(this.binaryFile.value) as File}
                returnCallback={formInfoCallback}
                isAnUpdate={this.isAnUpdate.value as boolean}
                offerId={(this.offerId.value) ? this.offerId.value as string : ""}
                offerType={this.offerType.value as string}
                validate={validate}
                validateCallback={this.validationResult.validate} />)
        }

        return (<div>{formFields}</div>);
    }

    setFieldFromName(propertyName: keyof MarketplaceItem, propertyValue?: any) {
        super.setFieldFromName(propertyName, propertyValue);

        // if (propertyName === "packType") {
        //     //console.log("removing binary due to packType change");
        //     this.binaryFile.value = null;
        //     durableOffer.validationResult.value = null;
        // }

        return this;
    }

    getApprovalButton(parentCallBack: React.MouseEventHandler<HTMLElement>, shipDate: string | number | Date, disabled: boolean) {
        var VSO_STATES = DurableOfferModel.VSO_STATES;
        console.log("in approval button");

        var isValid = false
        if (shipDate) {
            if (!isNaN(new Date(shipDate).getTime())) {
                isValid = true;
            }
        }

        if (this.cardState.value === VSO_STATES.PARTNER) {
            return <div className="d-grid gap-2">
                <Button
                    onClick={parentCallBack}
                    variant={"success"}
                    disabled={!isValid || disabled} >
                    {"APPROVE FOR PUBLISH"}
                </Button>
            </div>
        } else {
            return <div></div>;
        }
    }

    getVisibleProductDetails() {

        var productDetails: DisplayField<string>[] = [];
        // super.convertToDisplayField(productDetails, new OfferField(DurableOfferModel.getReadableModelName(), "Content Type"), null)
        super.convertToDisplayField(productDetails, this.retailProductId, CreateDeeplink(this.retailProductId.value));
        super.convertToDisplayField(productDetails, this.packageName);
        super.convertToDisplayField(productDetails, this.packType);
        super.convertToDisplayField(productDetails, this.marketplacePlatforms);
        
        return productDetails.map(FormatAsRow);

    }

    getComments() {
        if (this.comments.value != null) {
            return this.comments.value as string[];
        } else {
            return [];
        }
    }

    getActionButton(isFirstParty: boolean, onPartnerRequestResubmissionCallback: React.MouseEventHandler<HTMLElement>) {
        var data = this.convertToColumnData();

        var VSO_STATES = DurableOfferModel.VSO_STATES;
        let VSO_STATE_INFO = DurableOfferModel.VSO_STATE_INFO;
        let VSO_PARTNER_TESTING_STATE_INFO = DurableOfferModel.VSO_PARTNER_TESTING_STATE_INFO;
        let enableResubmissionRequest = !isFirstParty && (VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTING || VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTED) && VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].enableRequestResubmission;

        if (enableResubmissionRequest) {
            return (
                <div className="d-grid gap-2">
                    <Button id="bootstrap-overrides" variant={BUTTON_STATE.DANGER} onClick={onPartnerRequestResubmissionCallback} disabled={false}>REQUEST RESUBMISSION</Button>
                </div>
            );
        }

        else if (this.cardState.value === VSO_STATES.PARTNER) {
            var link = "/createUpdate/" + this.offerId.value + "/";
            return (
                <Link to={link} style={{ textDecoration: 'none' }}>
                    <div className="d-grid gap-2">
                        <Button id="bootstrap-overrides" variant={BUTTON_STATE.WARNING} disabled={false}> {BUTTON_TEXT.RESUBMIT} </Button>
                    </div>
                </Link>
            )
        }

        else {
            return (
                <Link to={data.buttonLinkPath} style={{ textDecoration: 'none' }}>
                    <div className="d-grid gap-2">
                        <Button id="bootstrap-overrides" variant={data.buttonState} disabled={!data.buttonValid}> {data.buttonText} </Button>
                    </div>
                </Link>
            );
        }
    }

    getTakedownButton(parentCallback: () => void) {
        const takeDownControl = this.cardState.value === "Closed"
                              ? (<Button id="bootstrap-overrides" variant={BUTTON_STATE.DANGER} onClick={parentCallback}
                                         disabled={false}>Take Down</Button>)
                              : null;
        return takeDownControl;
    }

    getIngestButton(parentCallback: () => void) {
        const ingestControl = (<div className="d-grid gap-2">
                <Button id="bootstrap-overrides" variant={BUTTON_STATE.DANGER} onClick={parentCallback} disabled={false}>COOK</Button>
            </div>);
        
        return ingestControl;
    }

    getPartnerTestingButtons(isFirstParty: boolean, onPartnerTestingCallback: (state?: string) => void) {
        let VSO_STATE_INFO = DurableOfferModel.VSO_STATE_INFO;
        let VSO_PARTNER_TESTING_STATE_INFO = DurableOfferModel.VSO_PARTNER_TESTING_STATE_INFO;
        let text = VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].buttonText;
        let newState = VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].newstate;
        let altNewState = VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].altNewState;
        let enablePartnerTestingButton = ((isFirstParty && VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].buttonEnabledForFirstParty) ||
                                          (!isFirstParty && VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].buttonEnabledForThirdParty)) &&
                                         (VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTING ||
                                          VSO_STATE_INFO[this.cardState.value as string].status === READABLE_VSO_STATES.TESTED);

        //return (<Button id="bootstrap-overrides" variant={BUTTON_STATE.READY} block onClick={parentCallback} disabled={false}>{text}</Button>);
        if (this.partnerTestingState.value === VSO_PARTNER_TESTING_STATES.STARTED) {
            let altText = VSO_PARTNER_TESTING_STATE_INFO[this.partnerTestingState.value as string].altButtonText;

            return (
                <div className="d-grid gap-2">
                    {enablePartnerTestingButton && <Button id="bootstrap-overrides" variant={BUTTON_STATE.READY} onClick={() => onPartnerTestingCallback(newState)} disabled={false}>{text}</Button>}
                    {enablePartnerTestingButton && <Button id="bootstrap-overrides" variant={BUTTON_STATE.DANGER} onClick={() => onPartnerTestingCallback(altNewState)} disabled={false}>{altText}</Button>}
                </div>
            );
        } else {
            return (
                enablePartnerTestingButton &&
                    <div className="d-grid gap-2">
                        <Button id="bootstrap-overrides" variant={BUTTON_STATE.READY} onClick={() => onPartnerTestingCallback(newState)} disabled={false}>{text}</Button>
                    </div>
            );
        }
    }

    getReadyToIngestButton(isFirstParty: boolean, onReadyToIngestCallback: React.MouseEventHandler<HTMLElement>) {
        return (
            <div className="d-grid gap-2">
                <Button id="bootstrap-overrides" variant={BUTTON_STATE.READY} onClick={onReadyToIngestCallback} disabled={false}>REQUEST INGESTION</Button>
            </div>
        );
    }

    getCarryOverSignOffState(onCarryOverSignOffState: (signOffState?: MisSignOffState) => void) {
        const compatibilityOptions = [
            {
                id: MisSignOffState.PendingVerification,
                name: "Please Select an Option",
                hidden: true
            },
            {
                id: MisSignOffState.Compatible_MarketplacePending,
                name: "Compatible"
            },
            {
                id: MisSignOffState.Incompatible,
                name: "Currently Incompatible",
                variant: "warning"
            },
            {
                id: MisSignOffState.Unsupported,
                name: "Permanently Incompatible",
                variant: "warning"
            }
        ];

        const marketplaceOptions = [
            {
                id: MisSignOffState.Compatible_MarketplacePending,
                name: "Please Select an Option",
                hidden: true
            },
            {
                id: MisSignOffState.Compatible_FeaturedInMarketplace,
                name: "Available",
                variant: "success"
            },
            {
                id: MisSignOffState.Compatible_NeverFeaturedInMarketplace,
                name: "Never Available",
                variant: "secondary"
            }
        ];

        return <div>
            <h4>{this.carryOverSignOffState.value?.startsWith("Compatible") ? "2024 Marketplace" : "2024 Compatibility"}</h4>
            <DropdownSelect
                value={this.carryOverSignOffState.value ?? MisSignOffState.PendingVerification}
                disabled={this.carryOverSignOffState.value === MisSignOffState.Compatible_NeverFeaturedInMarketplace}
                returnCallback={(value: MisSignOffState) => onCarryOverSignOffState(value)}
                options={this.carryOverSignOffState.value?.startsWith("Compatible") ? marketplaceOptions : compatibilityOptions} />
        </div>;
    }

    getCarryOverSignOffConfirmation(newSignOffState: MisSignOffState | undefined, onCarryOverSignOffStateConfirmation: (confirmation: boolean) => void) {
        switch (newSignOffState) {
            case MisSignOffState.Incompatible:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Do you wish to mark this ported MSFS 2020 content as “Currently Incompatible” with 2024? It will remain tagged as “Not Creator Approved” in My Library.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
            case MisSignOffState.Unsupported:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Do you wish to mark this ported MSFS 2020 content as “Permanently Incompatible” with 2024? It will remain tagged as “Not Creator Approved” in My Library.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
            case MisSignOffState.Compatible_MarketplacePending:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Have you verified your ported MSFS 2020 content in 2024 and approve removing its “Not Creator Approved” tag in My Library?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
            case MisSignOffState.Compatible_NeverFeaturedInMarketplace:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Do you wish to have your ported MSFS 2020 content never be available in the 2024 Marketplace?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
            case MisSignOffState.Compatible_FeaturedInMarketplace:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Do you approve making your ported MSFS 2020 content available in the 2024 Marketplace?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
            default:
                return <Modal show={true} onHide={() => onCarryOverSignOffStateConfirmation(false)} backdrop={"static"} keyboard={false}>
                    <Modal.Header>
                        <Modal.Title style={styles.center}>Have you verified your ported MSFS 2020 content and do you agree to its release on the MSFS 2024 Marketplace?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            <div style={styles.center} >
                                <h3 style={styles.warningText}>This operation cannot be undone.</h3>
                                <span>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(false)}><FontAwesomeIcon icon={faBackward} style={{paddingRight: 10}} />NO</Button>
                                <Button style={{margin: 10}} onClick={() => onCarryOverSignOffStateConfirmation(true)}>YES<FontAwesomeIcon icon={faForward} style={{paddingLeft: 10}} /></Button>
                                </span>
                            </div>
                        }
                    </Modal.Body>
                </Modal>;
        }
    }

    getPurchasableStatus() {
        const purchasableStatus: boolean = this.purchasable.value === true;
        return purchasableStatus;
    }

    static ParseDate(date: string) {
        try {
            var milli = Date.parse(date);
            if (isNaN(milli)) { milli = 0; }
            return new Date(milli);
        } catch(exception) { }

        return new Date(0);
    }

    static sortDateColumn(date1: string, date2: string) {
        const d1 = DurableOfferModel.ParseDate(date1);
        const d2 = DurableOfferModel.ParseDate(date2);
        return (d1 > d2) ? 1 : -1 ;
    }

    static getColumnHeaders(isFirstParty = false) {
        var columnStyle = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
        const columns = [
            {
                Header: 'Submitting Creator',
                accessor: 'creatorName', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle,
                show: isFirstParty
            },
            {
                Header: 'Marketplace Creator',
                accessor: 'marketplaceCreator', // String-based value accessors!
                maxWidth: 200,
                style: columnStyle
            },
            {
                Header: 'Seller Id',
                accessor: 'creatorId', // String-based value accessors!
                maxWidth: 80,
                style: columnStyle,
                show: isFirstParty
            },
            {
                Header: 'Title',
                accessor: 'offerTitle', // String-based value accessors!
                sortable: true,
                style: columnStyle,
                Cell: ({row}: any) => (
                    row.original.offerTitle
                )
            },
            {
                Header: 'Price',
                accessor: 'offerPrice', // String-based value accessors!
                maxWidth: 65,
                style: columnStyle,
                Cell: ({row}: any) => (
                    `$${row.original.offerPrice}`
                )
            },
            {
                Header: 'Package',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'packageName', // String-based value accessors!
                        style: columnStyle
                    },
                    {
                        Header: 'Type',
                        accessor: 'packType', // String-based value accessors!
                        maxWidth: 65,
                        style: columnStyle
                    }
                ]
            },
            {
                Header: 'PC',
                columns: [
                    {
                        Header: () => (<p>Initial<br/>Release Date</p>),
                        accessor: 'shipDateText', // String-based value accessors!
                        maxWidth: 100,
                        style: columnStyle
                    },
                    {
                        Header: () => (<p>Last Update<br/>Released</p>),
                        accessor: 'updateDateText', // String-based value accessors!
                        maxWidth: 100,
                        style: columnStyle
                    },
                    {
                        Header: 'Status',
                        accessor: 'cardStatus',
                        style: columnStyle,
                        maxWidth: 140,
                        Cell: ({row}: any) => (
                            <div>
                                <Link to={row.original.viewLinkPath} >PC {row.original.cardStatus}</Link>
                            </div>
                        )
                    },
                    {
                        Header: 'Action',
                        accessor: 'buttonTextSpecial',
                        maxWidth: 100,
                        sortable: true,
                        filterable: false,
                        style: columnStyle,
                        Cell: ({row}: any) => (

                            <Link to={row.original.buttonLinkPath} style={{ textDecoration: 'none' }}>
                                <div className="d-grid gap-2">
                                    <Button title={row.original.buttonTooltip} id="bootstrap-overrides" variant={row.original.buttonState} size="sm" disabled={!row.original.buttonValid}> {row.original.buttonText} </Button>
                                </div>
                            </Link>
                        )
                    }
                ]
            },
            {
                Header: 'Xbox',
                columns: [
                    {
                        Header: () => (<p>Initial<br/>Release Date</p>),
                        accessor: 'original.xbox.shipDateText', // String-based value accessors!
                        maxWidth: 100,
                        style: columnStyle,
                        Cell: ({row}: any) => row.original.xbox ? row.original.xbox.shipDateText : "N/A"
                    },
                    {
                        Header: () => (<p>Last Update<br/>Released</p>),
                        accessor: 'original.xbox.updateDateText', // String-based value accessors!
                        maxWidth: 100,
                        style: columnStyle,
                        Cell: ({row}: any) => row.original.xbox ? row.original.xbox.updateDateText : "N/A"
                    },
                    {
                        Header: 'Status',
                        accessor: 'original.xbox.cardStatus',
                        style: columnStyle,
                        maxWidth: 140,
                        Cell: ({row}: any) => (
                            row.original.xbox?.cardStatus ? <div>
                                <Link to={row.original.xbox.viewLinkPath} >Xbox {row.original.xbox.cardStatus}</Link>
                            </div> : "Not Submitted"
                        )
                    },
                    {
                        Header: 'Action',
                        accessor: 'original.xbox.buttonTextSpecial',
                        maxWidth: 100,
                        sortable: true,
                        filterable: false,
                        style: columnStyle,
                        Cell: ({row}: any) => {
                            let offer = row.original.xbox ?? row.original;
                            let buttonText = row.original.xbox?.buttonText ?? "SUBMIT";
                            let buttonState = row.original.xbox?.buttonState ?? BUTTON_STATE.READY;
                            let buttonLinkPath = row.original.xbox?.buttonLinkPath ?? "/createNewOffer/" + AUGER_OFFER_TYPES.DURABLE_OFFER + "/" + offer.cardId;
                            return <Link to={buttonLinkPath} style={{ textDecoration: 'none' }}>
                                <div className="d-grid gap-2">
                                    <Button title={offer.buttonTooltip} id="bootstrap-overrides" variant={buttonState} size="sm" disabled={!offer.buttonValid}> {buttonText} </Button>
                                </div>
                            </Link>
                        }
                    }
                ]
            }
        ]

        return columns;
    }

    isOfferUpdatable() {
        const {
            VSO_STATE_INFO,
        } = DurableOfferModel;

        var reportEntry = this.export();

        var vsoState = reportEntry.cardState;

        return vsoState ? VSO_STATE_INFO[vsoState].updateable : true;
    }

    convertToColumnData() {
        var optionsSimple: Intl.DateTimeFormatOptions = { hour12: true, year: "numeric", month: "numeric", day: "numeric" }

        const {
            VSO_STATE_INFO
        } = DurableOfferModel;

        var reportEntry = this.export();

        var vsoState = reportEntry.cardState;

        if (vsoState !== null && VSO_STATE_INFO[vsoState]) {
            // state can be null if ADO has a System.State value that this code does not recognize
            reportEntry.cardStatus = VSO_STATE_INFO[vsoState].status;
            reportEntry.lastModified = this.lastModified.value;

            reportEntry.buttonState = VSO_STATE_INFO[vsoState].buttonState;
            reportEntry.buttonText = VSO_STATE_INFO[vsoState].buttonText;
            reportEntry.buttonTooltip = VSO_STATE_INFO[vsoState].buttonTooltip;
            reportEntry.valid = VSO_STATE_INFO[vsoState].updateable;
            reportEntry.action = VSO_STATE_INFO[vsoState].action;
            reportEntry.initialReleaseDate = this.initialReleaseDate.value;
            reportEntry.lastUpdateReleased = this.lastUpdateReleased.value;
        }

        var viewLinkPath = "/viewOffer/" + reportEntry.offerId + "/";
        var buttonLinkPath = "";
        if (reportEntry.action === BUTTON_ACTION.UPDATE && reportEntry.valid) {
            buttonLinkPath = "/createUpdate/" + reportEntry.offerId + "/"
        } else if (reportEntry.action === BUTTON_ACTION.DETAILS) {
            buttonLinkPath = "/viewOffer/" + reportEntry.offerId + "/"
        }

        var shipDateText = "";
        var shipDate = JSON.stringify(new Date(8640000000000000))
        if (reportEntry.initialReleaseDate) {
            shipDateText = new Date(reportEntry.initialReleaseDate).toLocaleDateString(getLang(), optionsSimple)
            shipDate = JSON.stringify(new Date(reportEntry.initialReleaseDate));
        }

        var updateDateText = "";
        if (reportEntry.lastUpdateReleased) {
            updateDateText = new Date(reportEntry.lastUpdateReleased).toLocaleDateString(getLang(), optionsSimple)
        }

        //In order to get invalid buttons with the same text to sort below
        //valid ones, i add a tilde and declare a new hidden buttontext row
        //to be used for sorting and filtering
        var buttonTextSpecial = reportEntry.buttonText;
        if(reportEntry.valid)
        {
            buttonTextSpecial = buttonTextSpecial + "~";
        }

        return (
            {
                viewLinkPath: viewLinkPath,
                cardTitle: reportEntry.cardTitle,
                creatorName: reportEntry.creatorName,
                creatorId: reportEntry.creatorId,
                submittedVersion: reportEntry.submittedVersion,
                firstSubmittedDate: reportEntry.firstSubmittedDate,
                lastSubmittedDate: reportEntry.lastSubmittedDate,
                cardStatus: reportEntry.cardStatus,
                isAnUpdate: reportEntry.isAnUpdate,
                ingestedVersion: reportEntry.ingestedVersion,
                initialReleaseDate: reportEntry.initialReleaseDate,
                releasedVersion: reportEntry.releasedVersion,
                shipDate: shipDate,
                shipDateText: shipDateText,
                buttonState: reportEntry.buttonState,
                buttonValid: reportEntry.valid,
                cardId: reportEntry.cardId,
                buttonText: reportEntry.buttonText,
                buttonTextSpecial: buttonTextSpecial,
                buttonTooltip: reportEntry.buttonTooltip,
                buttonLinkPath: buttonLinkPath,
                lastModified: reportEntry.lastModified,
                marketplaceCreator: reportEntry.marketplaceCreator,
                packageName: reportEntry.packageName,
                packType: reportEntry.packType,
                offerPrice: reportEntry.offerPrice,
                offerTitle: reportEntry.offerTitle,
                updateDateText: updateDateText,
                workItemPlatform: reportEntry.workItemPlatform
            }
        )
    }

    static get defaultListSorting() {
        return [
            {
                id: "marketplaceCreator",
                desc: false
            },
            {
                id: "offerTitle",
                desc: false
            }
        ]
    }

    static get defaultFirstPartyListSorting() {
        return [
            {
                id: "creatorName",
                desc: false
            },
            {
                id: "offerTitle",
                desc: false
            }
        ]
    }

    static get MAX_FILE_SIZE() {
        return MAX_UPLOAD_SIZE;
    }
}