import React, { useEffect, useReducer, useState } from 'react';
import { Button } from 'react-bootstrap';
import Auth from '../Auth/Auth'
import { MarketplacePackage } from '../Models/AugerOffers/MarketplacePackage';
import { GetContentPackageDetails, GetDeveloperIds, GetMarketplacePackageDetails, IngestMarketplaceProduct, IngestMarketplaceProductCarryOverRental, UploadMediaAsset } from '../Api/RomaWebApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-regular-svg-icons';
import { MarketplaceProductThumbnails } from './MarketplaceProductThumbnails';
import { ContentPackageDetails, getLatestContentPackageDetailsResponse, getLatestMarketplacePackageDetailsResponse, getLatestMarketplacePackageSummaryResponse, getPackageName2020Prefix, getPackageNameNoPrefix, MarketplaceSummaryData, MisMediaAsset, MisPackageDetailsResponse, MisTag, PackageDetails, PlatformDetails, SubmissionType } from '../Models/AugerOffers/MarketplaceDetails';
import { MarketplaceProductRentals } from './MarketplaceProductRentals';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

interface Props {
    auth: Auth,
    isNew: boolean,
    validate: boolean,
    onSave: () => Promise<void>,
    onCancel: () => void,
    processFormInfo: (marketplacePackage?: MarketplacePackage, propertyName?: keyof MarketplacePackage, propertyValue?: any) => void,
    marketplacePackage: MarketplacePackage,
    marketplacePackageSummaries: MarketplaceSummaryData[],
    contentPackagesDetails: ContentPackageDetails[]
}

export function MarketplaceProductForm(props: Props) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const [isLoading, setIsLoading] = useState(!props.isNew);
    const [validateOffer, setValidateOffer] = useState(false);
    const [developerIds, setDeveloperIds] = useState([]);
    const [isModified, setIsModified] = useState(false);
    const [editMediaAssets, setEditMediaAssets] = useState(false);
    const [editRentals, setEditRentals] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [marketplacePackageDetails, setMarketplacePackageDetails] = useState<MisPackageDetailsResponse>();
    const [contentPackageDetails, setContentPackageDetails] = useState<PlatformDetails>();

    useEffect(() => {
        if (props.marketplacePackage.publisherId.value) {
            GetDeveloperIds(props.auth, props.marketplacePackage.publisherId.value).then((result: any) => {
                setDeveloperIds(result);
            });
        }
    }, [props.auth, props.marketplacePackage.publisherId.value]);

    useEffect(() => {
        if (!props.isNew && props.marketplacePackage.packageName.value) {
            GetMarketplacePackageDetails(props.auth, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string).then((result: PackageDetails<MisPackageDetailsResponse>) => {
                const details = getLatestMarketplacePackageDetailsResponse(result);
                setMarketplacePackageDetails(details);
                props.marketplacePackage.mediaAssets.value = details?.marketplaceInfo.layout?.mediaAssets.map(a => {return {...a}}) ?? [];
                props.marketplacePackage.mediaAssets.value.forEach((mediaAsset: MisMediaAsset) => { mediaAsset.url = details?.files?.find(file => file.name === mediaAsset.filename)?.url });
                props.marketplacePackage.mediaAssets.originalValue = props.marketplacePackage.mediaAssets.value;
                props.marketplacePackage.supportContact.value = details?.marketplaceInfo?.supportContact;
                props.marketplacePackage.supportContact.originalValue = details?.marketplaceInfo?.supportContact;
                props.marketplacePackage.secondSupportContactLabel.value = details?.marketplaceInfo?.secondSupportContactLabel;
                props.marketplacePackage.secondSupportContactLabel.originalValue = details?.marketplaceInfo?.secondSupportContactLabel;
                props.marketplacePackage.secondSupportContactLink.value = details?.marketplaceInfo?.secondSupportContactLink;
                props.marketplacePackage.secondSupportContactLink.originalValue = details?.marketplaceInfo?.secondSupportContactLink;
                props.marketplacePackage.tags.value = details?.marketplaceInfo?.tags;
                props.marketplacePackage.tags.originalValue = details?.marketplaceInfo?.tags;
                props.marketplacePackage.aircraftSpecifications.value = details?.marketplaceInfo?.aircraftSpecifications;
                props.marketplacePackage.locations.value = details?.marketplaceInfo?.locations?.map(location => [ location.airportCode, location.latLong.latitude, location.latLong.longitude ]) ?? [];
                props.marketplacePackage.historicalDescription.value = details?.localizedTexts["en-US"]?.texts["historicalDescription"];
                props.marketplacePackage.historicalDescription.originalValue = details?.localizedTexts["en-US"]?.texts["historicalDescription"];
                setIsLoading(false);
            });

            GetContentPackageDetails(props.auth, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string).then((result: ContentPackageDetails) => {
                const details = getLatestContentPackageDetailsResponse(result);
                setContentPackageDetails(details);
            });
        }
    }, [props.auth, props.marketplacePackage.packageName.value]);

    function processFormInfo(marketplacePackage?: MarketplacePackage, propertyName?: keyof MarketplacePackage, propertyValue?: any) { 
        props.processFormInfo(marketplacePackage, propertyName, propertyValue);

        setIsModified(true);
        forceUpdate();
    }

    function onEditMediaAssets() {
        setEditMediaAssets(true);
    }

    function onSaveMediaAssets(submission: MarketplacePackage) {
        setEditMediaAssets(false);
        setIsModified(true);
    }

    function onCancelEditMediaAssets() {
        props.marketplacePackage.mediaAssets.value = marketplacePackageDetails?.marketplaceInfo.layout?.mediaAssets.map(a => {return {...a}}) ?? [];
        props.marketplacePackage.mediaAssets.value.forEach((mediaAsset: MisMediaAsset) => { mediaAsset.url = marketplacePackageDetails?.files?.find(file => file.name === mediaAsset.filename)?.url });
        setEditMediaAssets(false);
        setIsModified(false);
    }

    function onEditRentals() {
        setEditRentals(true);
    }

    function onSaveRentals(submission: MarketplacePackage) {
        setEditRentals(false);
        setIsModified(true);
    }

    function onCancelEditRentals() {
        setEditRentals(false);
        setIsModified(false);
    }

    async function onSave() {
        const validationResult = props.marketplacePackage.validateOffer(true, true, false);
        const anythingModifiedBesidesRentalInfo = validationResult.results.some(result => result.propertyName !== "RentalInfo" && result.hasChanged);

        if (props.marketplacePackage.packageName.value?.startsWith("fs20-") && !anythingModifiedBesidesRentalInfo && props.marketplacePackage.rentalInfo.value) {
            await IngestMarketplaceProductCarryOverRental(props.auth, props.marketplacePackage.packageName.value, SubmissionType.Release, props.marketplacePackage.rentalInfo.value);
        } else {
            setValidateOffer(true);
            
            if (!validationResult.passed) {
                return;
            }

            setWaiting(true);
            
            props.marketplacePackage.submissionId.value = new Date().toISOString().replaceAll(':', '_') + ';' + getPackageNameNoPrefix(props.marketplacePackage.packageName.value);

            let marketplaceIngestRequest = props.marketplacePackage.MarketplaceIngestRequest;
            let isUpgrade = marketplaceIngestRequest.packageName.startsWith("fs20-");

            if (isUpgrade && marketplaceIngestRequest.marketplaceInfo?.tags) {
                marketplaceIngestRequest.marketplaceInfo.tags = marketplaceIngestRequest.marketplaceInfo.tags.filter(tag => tag !== MisTag.LEGACY);
            }

            marketplaceIngestRequest.filenameUploadUrls = {};

            if (props.marketplacePackage.mediaAssets.value) {
                await Promise.all(props.marketplacePackage.mediaAssets.value.map(async (mediaAsset: MisMediaAsset) => {
                    if (mediaAsset.file) {
                        marketplaceIngestRequest.filenameUploadUrls[mediaAsset.filename as keyof object] = await UploadMediaAsset(props.auth, mediaAsset.file, mediaAsset.filename, marketplaceIngestRequest.submissionId, getPackageNameNoPrefix(props.marketplacePackage.packageName.value) as string, marketplaceIngestRequest.developerId, marketplaceIngestRequest.publisherId);
                    } else if (mediaAsset.url && isUpgrade) {
                        marketplaceIngestRequest.filenameUploadUrls[mediaAsset.filename as keyof object] = mediaAsset.url;
                    }
                }));
            }

            const result = await IngestMarketplaceProduct(props.auth, marketplaceIngestRequest);

            const foundIndex = props.marketplacePackageSummaries.findIndex((marketplacePackage) => marketplacePackage.packageName === result.packageName);
            props.marketplacePackageSummaries[foundIndex] = result;
        }

        await props.onSave();
        setWaiting(false);
    }

    const marketplacePackageFormFields = props.marketplacePackage.getSubmissionFormFields(processFormInfo, props.isNew, validateOffer, true, true, false, {
        packageNames: [], // props.contentPackagesDetails?.map(contentPackageDetails => contentPackageDetails.packageName).filter(packageName => !props.marketplacePackagesDetails.map(marketplacePackageDetails => marketplacePackageDetails.packageName).includes(packageName)),
        developerIds: developerIds,
        auth: props.auth
    });

    const fs2020PackageName = getPackageName2020Prefix(props.marketplacePackage.packageName?.value);
    const marketplacePackageSummaryEntry = props.marketplacePackageSummaries?.find(marketplacePackageDetails => getLatestMarketplacePackageSummaryResponse(marketplacePackageDetails)?.packageName === fs2020PackageName);
    const contentPackageDetailsEntry = props.contentPackagesDetails?.find(contentPackageDetails => getLatestContentPackageDetailsResponse(contentPackageDetails)?.PC?.packageName === fs2020PackageName);
    const needsUpgradedPackage = marketplacePackageSummaryEntry != null && contentPackageDetailsEntry != null;

    const validOffer = !validateOffer || props.marketplacePackage.validateOffer(true, true, false).passed && !needsUpgradedPackage;

    const latestMarketplacePackageSummary = marketplacePackageSummaryEntry ? getLatestMarketplacePackageSummaryResponse(marketplacePackageSummaryEntry) : undefined;
    const isRentableType = ["LIVERY", "AIRCRAFT", "SCENERY", "PACK"].includes(props.marketplacePackage.packageContentType?.value ?? "");
    const isRentable = (!needsUpgradedPackage || latestMarketplacePackageSummary?.releaseStatus !== SubmissionType.Prerelease) && isRentableType;
    const rentalButtonTitle = needsUpgradedPackage && latestMarketplacePackageSummary?.releaseStatus === SubmissionType.Prerelease ? "Cannot set rental information for a ported product when in prerelease." : !isRentableType ? "Rental information is only available for liveries, aircraft, sceneries, and packs." : "Set rental information for this product.";

    return (
        <div className={"container create-new"} style={{cursor: waiting || isLoading ? "wait" : "default"}}>
            <div className="row">
                <main className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {props.marketplacePackage && <MarketplaceProductThumbnails key={editMediaAssets ? props.marketplacePackage.packageName?.value ?? "" : "mediaAssets"} show={editMediaAssets} auth={props.auth} marketplacePackage={props.marketplacePackage} onSave={onSaveMediaAssets} onCancel={onCancelEditMediaAssets} />}
                        {props.marketplacePackage && <MarketplaceProductRentals key={editRentals ? props.marketplacePackage.packageName?.value ?? "" : "rentals"} show={editRentals} auth={props.auth} marketplacePackage={props.marketplacePackage} marketplacePackageDetails={marketplacePackageDetails} onSave={onSaveRentals} onCancel={onCancelEditRentals} />}
                        {marketplacePackageFormFields}
                        <h2 style={{color: "red", textAlign: "center", visibility: validOffer === false ? "visible" : "hidden"}}>{needsUpgradedPackage ? "A 2024 package must be uploaded first." : "Please correct any errors indicated in red before saving."}</h2>
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <FontAwesomeIcon style={{cursor: isLoading ? "wait" : "default", position: "absolute", bottom: "30px", left: "50px"}} size="xl" icon={faImages} className="icon" onClick={isLoading ? undefined : () => onEditMediaAssets()} />
                            <FontAwesomeIcon title={rentalButtonTitle} style={{cursor: isLoading ? "wait" : "default", position: "absolute", bottom: "30px", left: "100px"}} size="xl" icon={faCoins} className="icon" onClick={isLoading || !isRentable ? undefined : () => onEditRentals()} />
                            <Button style={{ margin: 10, width: 100 }} variant="primary"
                                onClick={async event => await onSave()}
                                title={validOffer ? "" : needsUpgradedPackage ? "A 2024 package must be uploaded first." : "Please correct any errors indicated in red before saving."}
                                disabled={!isModified || waiting || isLoading || props.marketplacePackage.packageName.value == null || validateOffer && !validOffer}>{"SAVE"}</Button>
                            <Button style={{ margin: 10, width: 100 }} variant="default"
                                onClick={event => props.onCancel()}>{"CANCEL"}</Button>
                        </div>
                </main>
            </div>
        </div>
    );
}
