import { ImageGridFormFieldValue, NameValuePair } from "../../Components/FormFields/FormField"
import { VersionCompare } from "../../Constants/utils"

export enum MisTag
{
    WORLD_UPDATE = "WORLD_UPDATE",
    DYN_BUNDLE = "DYN_BUNDLE",
    FORCE_PACK = "FORCE_PACK",
    LEGACY = "LEGACY",
    STORE_OFFICIAL = "STORE_OFFICIAL",
    DEPRECATED = "DEPRECATED",
    DISCOUNT_DELUXE_OR_PREMIUM = "DISCOUNT_DELUXE_OR_PREMIUM",
    STORE_INSTALL_PC = "STORE_INSTALL_PC",
}

export enum MisPackageContentType
{
    Unknown = "UNKNOWN",
    Aircraft = "AIRCRAFT",
    Bundle = "BUNDLE",
    Currency = "CURRENCY",
    CurrencyPack = "CURRENCYPACK",
    Instruments = "INSTRUMENTS",
    Livery = "LIVERY",
    Misc = "MISC",
    Mission = "MISSION",
    Pack = "PACK",
    Recipe = "RECIPE",
    Scenery = "SCENERY",
    Subscription = "SUBSCRIPTION",
    Widget = "WIDGET"
}

enum MisPublishingPackageType
{
    Unknown = "Unknown",
    Bundle = "Bundle",
    InternalGroup = "InternalGroup",
    Pack = "Pack",
    Package = "Package",
    Subscription = "Subscription",
}

enum MisPublishingItemType
{
    Unknown = "Unknown",
    Package = "Package",
    PublishingGroup = "PublishingGroup",
}

export enum MisSignOffState
{
    PendingVerification = "PendingVerification",
    Unsupported = "Unsupported",
    Incompatible = "Incompatible",
    Compatible_MarketplacePending = "Compatible_MarketplacePending",
    Compatible_NeverFeaturedInMarketplace = "Compatible_NeverFeaturedInMarketplace",
    Compatible_FeaturedInMarketplace = "Compatible_FeaturedInMarketplace"
}

export enum SubmissionType
{
    None = "None",
    Draft = "Draft",
    Prerelease = "Prerelease",
    Release = "Release"
}

export enum RepositoryType
{
    Draft = "Draft",
    Live = "Live"
}

export enum MisMediaAssetTag
{
    Background = "Background",
    Carrousel = "Carrousel",
    Thumbnail = "Thumbnail",
    Library = "Library",
    Livery = "Livery"
}

interface MisBusinessInfo
{
    priceInUSD: string,
    upgradePriceInUSD?: string,
    catalogPrice?: string,
    thirdPartyShortName: string,
    thirdPartyUaid?: string,
    releaseDate?: string
}

interface MisMarketplaceInfo
{
    sections: MisSection[],
    locations?: MisLocation[],
    aircraftSpecifications?: NameValuePair[],
    tags?: MisTag[],
    keywords?: string[],
    creator?: string,
    supportContact?: string,
    secondSupportContactLabel?: string,
    secondSupportContactLink?: string,
    title: string,
    layout?: MisMarketplaceLayout,
    packageContentType: MisPackageContentType,
    publishingPackageType?: MisPublishingPackageType,
    publishingItemType?: MisPublishingItemType,
    referencedPackages?: string[]
}

interface MisSection
{
    category: string,
    section: string
}

export interface MisLocation
{
    type: string,
    airportCode: string,
    latLong: MisLatLong
}

interface MisLatLong
{
    latitude: string,
    longitude: string
}

export interface MisMarketplaceLayout
{
    mediaAssets: MisMediaAsset[],
}

export type MisMediaAsset = ImageGridFormFieldValue;

export interface MisPackageContentFileProperties
{
    name: string,
    url: string,
    contentHash: string
}

export interface MisPrices
{
    priceInUSD: string,
    durationInDays: number
}

export interface MisFreeTrial
{
    durationInDays: number,
    version: number
}

export interface MisRentalInfo
{
    freeTrial?: MisFreeTrial,
    prices: MisPrices[]
}

export enum MisStatus
{
    Unset = "Unset",
    Live = "Live",
    WaitingForFileUpload = "WaitingForFileUpload",
    Commited = "Commited",
    Processing = "Processing",
    Error = "Error"
}

export interface MisPackageDetailsResponse {
    packageName: string,
    playfabTitleId: string,
    repository?: string,
    businessInfo: MisBusinessInfo,
    submissionId?: string,
    publisherId?: string,
    developerId?: string,
    releaseStatus?: SubmissionType,
    ingestionStatus?: MisStatus,
    ingestionVersion?: number,
    latestFreeTrialVersion?: number,
    metadata: any,
    marketplaceInfo: MisMarketplaceInfo,
    rentalInfo?: MisRentalInfo,
    files: MisPackageContentFileProperties[],
    localizedTexts: any
}

export interface MarketplaceIngestRequest
{
    submissionId: string;
    businessInfo: MisBusinessInfo;
    packageName: string,
    developerId: string,
    publisherId: string,
    metadata?: string[],
    marketplaceInfo: MisMarketplaceInfo,
    filenameUploadUrls?: any,
    deletedFilenames?: string[],
    deleteExistingFiles: boolean,
    localizedTexts?: any,
    waitForContentIngestion: boolean,
    rentalInfo?: MisRentalInfo
}

export interface MarketplaceSummary {
    ingestionVersion: number,
    submissionId: string,
    packageName: string,
    publisherId: string,
    developerId: string,
    priceInUSD: string,
    upgradePriceInUSD?: string,
    releaseStatus: SubmissionType,
    ingestionStatus: MisStatus,
    title: string,
    creator: string,
    packageContentType: MisPackageContentType,
    publishingItemType: MisPublishingItemType,
    category: string,
    section: string,
    description: string,
    keywords: string[]
}

export type PlatformDetails = Record<PublishingTarget, CidsPackageDetailsResponse>;

export interface PackageDetails<T> {
    packageName: string,
    publisherId: string,
    developerId: string,
    lastModified: string,
    draft: T,
    prerelease: T,
    release: T,
}

export interface ContentPackageDetails extends PackageDetails<PlatformDetails> {
    pcProcessingStatus: SubmissionType,
    xboxProcessingStatus: SubmissionType
}

export enum AdoStatus {
    ProductQueued = "Product Queued",
    ProductTransferred = "Product Transferred",
    PartnerActionRequired = "Partner Action Required",
    ProductResubmitted = "Product Resubmitted",
    TestingRequested = "Testing Requested",
    ReadyForTesting = "Ready for Testing",
    TestingInProgress = "Testing In Progress",
    TestingCompleted = "Testing Completed",
    ReadyForRelease = "Ready For Release",
    SentForRelease = "Sent For Release",
    ProductReleased = "Product Released",
    ReleaseArchived = "Release Archived",
    ReleaseError = "Release Error"
}

export interface AdoStatusDetails {
    workItemId?: number;
    submissionId?: string;
    status?: string;
    changedDate?: string;
}

export interface AdoStatusSummary {
    packageName?: string;
    marketplaceAdoStatus: AdoStatusDetails;
    contentAdoStatus: Record<PublishingTarget, AdoStatusDetails>;
}

export interface MarketplaceSummaryData extends PackageDetails<MarketplaceSummary> {
    processingStatus?: SubmissionType
    adoStatus?: AdoStatusSummary
}

export interface CidsPackageDetailsResponse {
    packageName: string;
    contentRepository: string;
    version?: string;
    submissionId?: string;
    developerId?: string;
    publisherId?: string;
    publishingTarget?: PublishingTarget;
    releaseStatus?: SubmissionType;
    isIngested?: boolean;
    isIngestionError?: boolean;
    isReleased?: boolean;
    creationDateUtc?: Date;
    ingestionDateUtc?: Date;
}

export enum PublishingTarget
{
    Xbox = "Xbox",
    PC = "PC",
    PCAndXbox = "PCAndXbox"
}

export interface ProductReleaseRequest
{
    packageName?: string;
    developerId?: string;
    publisherId?: string;
    marketplaceRelease?: boolean;
    contentRelease?: boolean;
    publishingTargets?: PublishingTarget[];
}

export interface ProductReleaseResponse
{
    packageName?: string;
    MarketplaceVersion?: ProductReleaseDetails;
    contentVersion?: Record<PublishingTarget, ProductReleaseDetails>;
}

export interface ProductReleaseDetails {
    packageDetails?: MisPackageDetailsResponse | CidsPackageDetailsResponse;
    isSuccess? : boolean;
}

export interface ContentIngestionRequest {
    submissionId?: string;
    packageName?: string;
    publishingTarget?: PublishingTarget;
    publisherId?: string;
    developerId?: string;
}

export function getLatestMarketplacePackageSummaryResponse(packageDetails: MarketplaceSummaryData): MarketplaceSummary {
    let prereleaseOrRelease = (packageDetails.prerelease?.ingestionVersion ?? -1) > (packageDetails.release?.ingestionVersion ?? -1) ? packageDetails.prerelease : packageDetails.release;
    let latest = prereleaseOrRelease && (!packageDetails.draft || prereleaseOrRelease.submissionId === packageDetails.draft.submissionId) ? prereleaseOrRelease : packageDetails.draft;

    return latest;
}

export function getLatestMarketplacePackageDetailsResponse(packageDetails: PackageDetails<MisPackageDetailsResponse>): MisPackageDetailsResponse {
    let prereleaseOrRelease = (packageDetails.prerelease?.ingestionVersion ?? -1) > (packageDetails.release?.ingestionVersion ?? -1) ? packageDetails.prerelease : packageDetails.release;
    let latest = prereleaseOrRelease && (!packageDetails.draft || prereleaseOrRelease.submissionId === packageDetails.draft.submissionId) ? prereleaseOrRelease : packageDetails.draft;

    return latest;
}

export function getLatestContentPackageDetailsResponse(packageDetails: ContentPackageDetails): PlatformDetails {
    let prereleaseOrReleasePC = VersionCompare(packageDetails.prerelease?.PC?.version, packageDetails.release?.PC?.version) < 0 ? packageDetails.prerelease?.PC : packageDetails.release?.PC;
    let prereleaseOrReleaseXbox = VersionCompare(packageDetails.prerelease?.Xbox?.version, packageDetails.release?.Xbox?.version) < 0 ? packageDetails.prerelease?.Xbox : packageDetails.release?.Xbox;

    let latest = {
        PC: prereleaseOrReleasePC && (!packageDetails.draft?.PC || prereleaseOrReleasePC.submissionId === packageDetails.draft.PC.submissionId) ? prereleaseOrReleasePC : packageDetails.draft?.PC,
        Xbox: prereleaseOrReleaseXbox && (!packageDetails.draft?.Xbox || prereleaseOrReleaseXbox.submissionId === packageDetails.draft.Xbox.submissionId) ? prereleaseOrReleaseXbox : packageDetails.draft?.Xbox
    } as PlatformDetails;

    return latest;
}

export function getPackageNameNoPrefix(packageName?: string): string | undefined {
    return packageName?.replace("fs20-", "")?.replace("fs24-", "");
}

export function getPackageName2020Prefix(packageName?: string): string | undefined {
    return  "fs20-" + getPackageNameNoPrefix(packageName);
}

export function releaseInProgress(status?: string): boolean {
    if (!status) {
        return false;
    }

    return status !== AdoStatus.ReleaseArchived && status !== AdoStatus.ProductReleased;
}
